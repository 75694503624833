

import DxList from 'devextreme-vue/list';
import JobTitleService from '../services/jobtitle-service';


export default {
  name: "JobTitleTemplate",
  components: { DxList },
  props: {
    templateData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    const {
      nombre,
    } = this.templateData;

    return {
      id_puesto: null,
      nombre: null,
      dataSourceJobTitles: [
        { id: 1, descripcion: 'I.- Garantizar el derecho de toda persona a vivir en un medio ambiente sano para su desarrollo,salud y bienestar;' },
        { id: 2, descripcion: 'II.- Definir los principios de la política ambiental y los instrumentos para su aplicación;' },
        { id: 3, descripcion: 'III.- La preservación, la restauración y el mejoramiento del ambiente;' },
        { id: 4, descripcion: 'IV.- La preservación y protección de la biodiversidad, así como el establecimiento y administración de las áreas naturales protegidas;' },
        { id: 5, descripcion: 'V.- El aprovechamiento sustentable, la preservación y, en su caso, la restauración del suelo, elagua y los demás recursos naturales, de manera que sean compatibles la obtención debeneficios económicos y las actividades de la sociedad con la preservación de los ecosistemas;' },

        { id: 6, descripcion: 'VI.- La prevención y el control de la contaminación del aire, agua y suelo;' },
        { id: 7, descripcion: 'VII.- Garantizar la participación corresponsable de las personas, en forma individual o colectiva, enla preservación y restauración del equilibrio ecológico y la protección al ambiente;' },
        { id: 8, descripcion: 'VIII.- El ejercicio de las atribuciones que en materia ambiental corresponde a la Federación, lasentidades federativas, los Municipios y las demarcaciones territoriales de la Ciudad de México, bajo el principio de concurrencia previsto en el Artículo 73 fracción XXIX - G de la Constitución; Fracción reformada DOF 19-01-2018' },
        { id: 9, descripcion: 'IX.-El establecimiento de los mecanismos de coordinación, inducción y concertación entreautoridades, entre éstas y las Instituciones académicas y de investigación, los sectores social yprivado, así como con personas y grupos sociales, en materia ambiental. Fracción reformada DOF 11-04-2022' },
        { id: 10, descripcion: 'X.- El establecimiento de medidas de control y de seguridad para garantizar el cumplimiento y la aplicación de esta Ley y de las disposiciones que de ella se deriven, así como para la imposición de las sanciones administrativas y penales que correspondan.' },

      ],
      selectedJobTitles: [],

      detailInfo: `${nombre}`,
    };
  },
  created() {
    this.jobTitleService = new JobTitleService();

  },
  mounted() {


  },
};
