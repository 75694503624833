import axios from 'axios';

export default class JobTitleService {

	getPuestos() {			
        const url = process.env.VUE_APP_APIURL + `puestos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getPuesto(puesto) {
        const url = process.env.VUE_APP_APIURL + `/puestos/${puesto.id_puesto}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updatePuesto(puesto){
        const url = process.env.VUE_APP_APIURL + `/puestos/${puesto.id_puesto}`;
		return axios.put(url,puesto).then(res=>res.data.data);
	}

	createPuesto(puesto){
        const url = process.env.VUE_APP_APIURL + `/puestos`;
		return axios.post(url,puesto).then(res=>res.data.data);
	}

	deletePuesto(puesto){
        const url = process.env.VUE_APP_APIURL + `/puestos/${puesto.id_puesto}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}